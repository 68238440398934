//TODO: COnsolidate with clubsConfig.
// Address can live on the cosmic side.
export default Object.freeze({
  'coco-bongo-playa-del-carmen': {
    name: 'Coco Bongo Cancun',
    price: 3,
    directions: {
      address:
        'Blvd. Kukulcan 30, Punta Cancun, Zona Hotelera, 77500 Cancún, Q.R.',
      googleMap:
        'pb=!1m18!1m12!1m3!1d10372.389792192842!2d-86.75455744712895!3d21.128124384564536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2bf1d63b6b99%3A0x6cc32e706b78b7bf!2sCoco%20Bongo!5e0!3m2!1ses!2ses!4v1726575274407!5m2!1ses!2ses',
    },
    tags: [
      {label: 'DAY', value: '-- -- ', desc: 'Closed During The Day.'},
      {
        label: 'NIGHT',
        value: '9PM-TIL LATE',
        desc:
          'Filled with energy and life during the night. From 9PM until the late hours. Daily. ',
      },
      {
        label: 'SHOW',
        value: 'DJs/SHOW + PERFORMANCES',
        desc: 'Nightclub vibes and dance floor, also a variety of themed SHOWS and PERFORMANCES. Constantly updated.',
      },
      {
        label: 'MUSIC',
        value: 'DIVERSE',
        desc:
          'Coco Bongo offers a diverse range of music to suit all tastes, which changes depending on the themed shows..',
      },
      {
        label: 'DRESS',
        value: 'PARTY/CASUAL',
        desc:
          'No formal dresscode. DRESS TO PARTY!',
      },
      {
        label: 'GOOD FOR',
        value: 'SIGNATURE CANCUN PARTY',
        desc:
          'Good for everyone ! CocoBongo offers a signature party of Cancun, one that no one should miss!.',
      },
    ],
  },
  'ilios-playa-del-carmen': {
    name: 'Ilios Playa del Carmen',
    price: 4,
    directions: {
      address: '5 Avenida, Calle Corazón, Centro, 77710 Playa del Carmen, Q.R.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14936.101067387108!2d-87.0716303!3d20.6278269!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4e43ba30a8afcb%3A0xb0c267d9d02f7037!2sIlios!5e0!3m2!1sen!2smx!4v1728499158163!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '-', desc: 'Closed during the day.'},
      {label: 'NIGHT', value: '5PM-1AM', desc: 'Restaurant open daily.'},
      {
        label: 'SHOW',
        value: 'DINNER+SHOW',
        desc: 'Greek dining experience with fire shows, violin shows, live music, and plate-smashing.'
      },
      {label: 'MUSIC', value: 'LIVE/LATIN', desc: 'Residents DJs and live music.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Smart Casual Attire.'},
      {label: 'GOOD FOR', value: 'DINNER', desc: 'To celebrate special occasions or for a unique Greek dinner.'},
    ],
  },
  'porfirios-playa-del-carmen': {
    name: 'Porfirios',
    price: 4,
    directions: {
      address:
        '5ta Avenida, esq. Calle 12 Col, Calle 12 Nte, Centro, Gonzalo Guerrero, 77710 Playa del Carmen, Q.R., Mexico',
      googleMap:
        'pb=!1m18!1m12!1m3!1d7468.061038306718!2d-87.07444822658016!3d20.627612801439184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4e431a0d482355%3A0x4b938f130e6d1e86!2sPorfirio&#39;s%20Playa%20del%20Carmen%20%7C%20Restaurante%20de%20comida%20mexicana!5e0!3m2!1sen!2ses!4v1729077538008!5m2!1sen!2ses',
    },
    tags: [
      {label: 'DAY', value: '1PM-7PM', desc: 'Opens daily for lunch.'},
      {
        label: 'NIGHT',
        value: '7PM-1AM',
        desc:
          'Opens daily for dinner. ',
      },
      {
        label: 'FOOD',
        value: 'TRADITIONAL MEXICAN GOURMET',
        desc: 'Mexican gourmet cuisine for those who enjoy a taste of our origins.',
      },
      {
        label: 'MUSIC',
        value: 'LIVE LATIN & HOUSE',
        desc:
          'Live Music for a perfect dinner setting.',
      },
      {
        label: 'DRESS',
        value: 'SMART-CASUAL',
        desc:
          'Elegant/Smart Casual',
      },
      {
        label: 'GOOD FOR',
        value: 'CELEBRATION DINNER',
        desc:
          'Porfirios in Playa del Carmen is a famous spot for a premium mexican experience acompannied by the best traditional gourmet food.',
      },
    ],
  },
  "la-vicenta-playa-del-carmen": {
    name: 'La Vicenta Playa Del Carmen',
    price: 3,
    directions: {
      address: 'Centro I, Quinta Avenida 5-L5, entre 5 Y, C. 10 Nte, Centro, 77710 Playa del Carmen, Q.R.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14936.213088770974!2d-87.0724156!3d20.6266853!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4e4354b2b0bb9d%3A0xd51851aa0c1dfb4!2sLa%20Vicenta%20%7C%20Steakhouse%20in%20Playa%20del%20Carmen!5e0!3m2!1sen!2smx!4v1728404993226!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '1PM-7PM', desc: 'A relaxing restaurant with great steak options.'},
      {label: 'NIGHT', value: '7PM-2AM', desc: 'The perfect spot to share food and drinks with friends.'},
      {label: 'SHOW', value: 'LIVE MUSIC', desc: 'Some days of the week you will find live music.'},
      {label: 'MUSIC', value: 'LIVE/LATIN', desc: 'Residents DJs and live music.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Smart Casual Attire.'},
      {label: 'GOOD FOR', value: 'STEAK', desc: 'Grillhouse and bar.'},
    ],
  },
  "on-heaven-playa-del-carmen": {
    name: 'On Heaven Playa del Carmen',
    price: 2,
    directions: {
      address: 'Quinta Avenida 152, Centro, 77710 Playa del Carmen, Q.R.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14936.285159660723!2d-87.0730659!3d20.6259508!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4e435ca0f4945d%3A0x769daa73a883d22b!2sOn%20Heaven!5e0!3m2!1sen!2smx!4v1730506848130!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '-', desc: 'Closed during the day.'},
      {label: 'NIGHT', value: '9PM-3AM', desc: 'Open from Wednesday to Sunday.'},
      {label: 'SHOW', value: 'DJ SET', desc: 'Rooftop sets from several DJs.'},
      {label: 'MUSIC', value: 'TECH/HOUSE', desc: 'International and Local DJ Sets.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Smart Casual Attire.'},
      {label: 'GOOD FOR', value: 'LATE PARTY', desc: 'Late night rooftop to dance & enjoy music.'},
    ],
  },
  "martina-beach-club-playa-del-carmen": {
    name: 'Martina Playa del Carmen',
    price: 2,
    directions: {
      address: 'Albatros, Zazil-ha, 77720 Playa del Carmen, Q.R.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14935.38314003379!2d-87.0615889!3d20.6351418!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4e5cd4c741ea9d%3A0xebe79458b0567657!2sMartina%20Beach%20Club!5e0!3m2!1sen!2smx!4v1731436956982!5m2!1sen!2smx'
    },
    tags: [
      { label: 'DAY', value: '8AM-8PM', desc: 'Beach Club with live music and special shows.' },
      { label: 'NIGHT', value: '-', desc: 'Closed during the night.' },
      { label: 'SHOW', value: 'LIVE MUSIC', desc: 'Beach Club shows and pool activities.' },
      { label: 'MUSIC', value: 'LATIN/LIVE', desc: 'Local DJ Sets.' },
      { label: 'DRESS', value: 'RELAXED', desc: 'Beach-themed attire.' },
      { label: 'GOOD FOR', value: 'BEACH', desc: 'Enjoy the beach, live music and great company.' },
    ],
  }
});

