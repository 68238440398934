<template>
  <div style="margin: 5px">
    <section
      v-if="withRsvp"
      class="col-sm-12 text-center"
      style="padding: 1px"
      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
      <h4 class="section-header" style="font-weight: bold;font-size: 19px;font-family: 'Oswald', sans-serif"
          :style="{ backgroundColor: this.secondaryColor }"

      >
        <img
          v-lazy="this.logo + '?w=30&h=30&fit=scale'"
          alt="tulum.party"
          height="30px"
          width="30px"
          style="border-radius: 300px"
        />
        {{ topSectionName }}
      </h4>

      <div style="margin:25px">
        <slot>
        </slot>
        <RsvpViaDialog
          :text="ctaText"

          mail-address="info@tulum.party"
          :legend=dialogLegend
          :message="message"
          footer="Enjoy Tulum As It Fullest"
          :club-config="{
            name: 'tulum.party',
           primaryColor: this.primaryColor,
           secondaryColor: this.secondaryColor}"
        ></RsvpViaDialog>
      </div>
    </section>

    <hr :style="{height: '2px', backgroundColor: `${this.primaryColor}`}">
    <section
      class="col-sm-12"
      style="padding: 1px;padding-bottom: 40px"
      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">



    <h4 class="section-header">
        <i class="tim-icons icon-settings"></i>&nbsp;TOOLS
      </h4>
      <div style="margin: 10px;padding: 10px" class="text-left">
        <small >Tools to elevate your party time.</small>
      </div>
      <MiniCard
        title="[NEW] Tulum Today"
        text="Your Daily Guide"
        link="/tulum-events-today"
        image="https://imgix.cosmicjs.com/15cd3df0-02af-11f0-9c94-c1d9b01cc071-e7b0917d2772c83bd52a9d011624c536.webp"
      ></MiniCard>
      <MiniCard
        title="Party Blog"
        text="TULUM DECODED"
        link="/blog"
        image="https://imgix.cosmicjs.com/5a39f560-c8f3-11ee-9ce5-59949019255e-day-zero-main-stage.jpg"
      ></MiniCard>
      <MiniCard
        title="Community"
        text="+ 4000 Members"
        link="/l?u=https://t.me/tulumparty"
        :image="tpLogo"
      ></MiniCard>

      <MiniCard
        title="DJ Schedule"
        text="Who is playing?"
        link="/tulum-dj-schedule"
        :image="djImage"
      ></MiniCard>
      <MiniCard
        title="Event Calendar"
        text="Do Not Miss A Thing !"
        link="/tulum-party-mexico-event-calendar"
        :image="calendar"
      ></MiniCard>
      <MiniCard
        title="Top Clubs"
        text="Dance & Have Fun"
        link="/tulum-clubs"
        image="https://imgix.cosmicjs.com/7c831370-02b0-11f0-9c94-c1d9b01cc071-78e4e810e3fba8e4051017e27e15419c.webp"
      ></MiniCard>

    </section>

    <hr :style="{height: '2px', backgroundColor: `${this.primaryColor}`}">

    <section
      class="col-sm-12"
      style="padding: 1px;padding-bottom: 40px"
      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
      <h4 class="section-header">
        <img v-lazy="fire+'?w=35&h=35'" height="35px" width="35x">
        &nbsp; POPULAR
      </h4>

      <DynamicLinks :links="links"
                    :style="{ background: `linear-gradient(45deg, black, ${this.primaryColor})` }"
      ></DynamicLinks>

    </section>


    <section
      class="col-sm-12 text-center"
      style="padding: 1px"
      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">

      <h4 class="section-header">
        <i class="tim-icons icon-vector"></i>
        CONNECT
      </h4>
      <hr style="height: 2px" :style="{ backgroundColor: this.primaryColor }"/>

      <router-link :to="{path: '/l?u=https://www.instagram.com/stories/tulum.party/'}">
        <img v-lazy="instaFeed+'?w=320&h=220&fit=clamp'" height="220px" width="320px">
      </router-link>

      <p style="padding: 30px" class="text-center">Check Our <strong>Instagram Stories</strong> For More</p>
      <br>
      <ul class="list-unstyled">

        <li>
          <router-link :to="{path: '/l?u=https://www.instagram.com/tulum.party/'}">
            <img v-lazy="instagramLogo" height="20px" width="20px"> Instagram
          </router-link>

        <li><img v-lazy="faceLogo" height="20px" width="20px"> <a href="https://www.facebook.com/tulum.party">Facebook</a></li>
      </ul>
    </section>
    <div class="card text-center centered" style="padding: 30px">
      <img v-lazy="tulumPartyLogo" height="15" width="105" class="centered" alt="tulum party logo">
      <small style="font-weight: lighter;font-size: 10px">PLAN A | SIDE B</small>
    </div>
  </div>
</template>
<script>
import DynamicLinks from "@/components/DynamicLinks"
import MiniCard from '@/components/Cards/MiniCard'
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog"
// import ImgCard from "@/components/Cards/ImgCard";

export default {
  name: 'SmartMenu',
  data: () => {
    return {
      clicked: false,
      sanDJ: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      tpLogo: 'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      instagramLogo: 'https://imgix.cosmicjs.com/a9bc32f0-3fd5-11ec-99ca-fbde970234a8-image.png',
      faceLogo: 'https://imgix.cosmicjs.com/c49b9530-3fd4-11ec-99ca-fbde970234a8-image.png',
      tulumPartyLogo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png?w=105&h=15&fit=clamp',
      calendar: 'https://imgix.cosmicjs.com/fdb719e0-e8fd-11ef-b333-e101bec29f3d-e82abebd3cb5882d4423c7666a57660b.webp',
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      fire: 'https://imgix.cosmicjs.com/15cd3df0-02af-11f0-9c94-c1d9b01cc071-e7b0917d2772c83bd52a9d011624c536.webp',
      instaFeed: 'https://imgix.cosmicjs.com/a1e3c280-0531-11f0-993b-3bd041905fff-IMG_2203.jpg'
    }
  },
  components: {DynamicLinks, MiniCard, RsvpViaDialog},
  props: {
    links: {},
    logo: '',
    primaryColor: {
      type: String,
      default: '#323232'
    },
    secondaryColor: {
      type: String,
      default: '#1c1c1c'
    },
    withRsvp: {
      type: Boolean,
      default: true,
    },
    topSectionName: {
      type: String,
      default: 'GET IN TOUCH'
    },
    ctaText: {
      type: String,
      default: 'Hit us up!'
    },
    dialogLegend: {
      type: String,
      default: 'Day Or Night Its Party Time'
    },
    message: {
      type: String,
      default: 'Hi! How Can We Help?'
    }
  }
}
</script>
